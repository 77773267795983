<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="每天单价" prop="unitPrice">
                <a-input v-model="queryParam.unitPrice" placeholder="请输入每天单价" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="ios内购产品id" prop="productId">-->
<!--                <a-input v-model="queryParam.productId" placeholder="请输入ios内购产品id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="总价" prop="totalPrice">
                <a-input v-model="queryParam.totalPrice" placeholder="请输入总价" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery()"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['vip:menu:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['vip:menu:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['vip:menu:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['vip:menu:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>


<!--        <span slot="isOpen" slot-scope="text, record">-->
<!--          &lt;!&ndash; <dict-tag :options="dict.type['sys_show_hide']" :value="record.status" /> &ndash;&gt;-->
<!--          <a-popconfirm ok-text="是" cancel-text="否" @confirm="editIsopen(record)"-->
<!--                        @cancel="cancelHandleStatus(record)">-->
<!--            <span slot="title">确认<b>{{ record.status === 0 ? '关闭' : '开启' }}</b>吗?</span>-->
<!--            <a-switch checked-children="开" un-checked-children="关" :checked="record.status === 0" />-->
<!--          </a-popconfirm>-->

          <span slot="isOpen" slot-scope="text, record">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="record.isOpen == 1" @change="changeIsOpen($event,record)"/>
        </span>



        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['vip:menu:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['vip:menu:edit']">
            <a-icon type="edit" />修改
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['vip:menu:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['vip:menu:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
        :rowKey="(record, index) => index + 1"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageMenu,listMenu, delMenu,changeIsOpen } from '@/api/vip/menu'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Menu',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        unitPrice: null,
        productId: null,
        totalPrice: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '序号',
          // ellipsis: true,,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '周会员'
            }else if(t == 1) {
              return '月会员'
            }else if(t == 2) {
              return '年会员'
            }
          }
        },



        {
          title: '是否启用',
          dataIndex: 'isOpen',
          align: 'center',
          scopedSlots: { customRender: 'isOpen' },
        },

        {
          title: '每天单价',
          dataIndex: 'unitPrice',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '英文备注',
          dataIndex: 'remarkEn',
          align: 'center'
        },
        // {
        //   title: 'ios内购产品id',
        //   dataIndex: 'productId',
        //   align: 'center'
        // },
        {
          title: '总价',
          dataIndex: 'totalPrice',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询会员菜单列表 */
    getList () {
      this.loading = true
     pageMenu(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    changeIsOpen (e,record) {
      record.isOpen = e ? 1 : 0
      changeIsOpen(record).then(response => {
        this.$message.success(
            '操作成功'
        )
        return  record.isOpen = e ? 1 : 0
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        unitPrice: undefined,
        productId: undefined,
        totalPrice: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delMenu(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('vip/vip-menu/export', {
            ...that.queryParam
          }, `会员菜单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }

    },


}
</script>
